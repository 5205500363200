const MyProfileView = () =>
  import(/* webpackChunkName: "users" */ '@/modules/users/views/MyProfileView')

const NotificationsView = () =>
  import(
    /* webpackChunkName: "users" */ '@/modules/users/views/HistoryNotifications'
  )

export default [
  {
    path: '/perfil',
    name: 'MyProfile',
    meta: {
      title: 'Mi perfil',
      rule: 'seller',
    },
    component: MyProfileView,
  },
  {
    path: '/notifications',
    name: 'NotificationsUser',
    meta: {
      title: 'Notificaciones',
      rule: 'seller',
    },
    component: NotificationsView,
  },
]
