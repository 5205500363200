import { roles } from '@/utils/utils'
/**
 * Get one product from storage
 * @param state
 */
export function isLogged(state) {
  return !!state.tokenAccess
}

export function isManagerOrAdmin(state) {
  return (
    state.user.userprofile.role === roles.ADMIN ||
    state.user.userprofile.role === roles.MANAGER
  )
}

export function getUsername(state) {
  return state.user.username
}

export function isAdmin(state) {
  return state.user.userprofile.role === roles.ADMIN
}
