<template>
  <footer id="main-footer">
    <div class="footer-header text-muted">
      <vs-row
        vs-align="center"
        vs-type="flex"
        vs-justify="space-around"
        vs-w="12"
        class="container-fluid"
      >
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="3"
        >
          <a
            class="link"
            href="https://fygsolutions.com/"
            target="_blank"
          >
            Made with ♥ by FyG Solutions
          </a>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="9"
        >
          <a
            class="link ml-5"
            href="https://docs.cohuashop.com"
            target="_blank"
          >
            Aumentar mi plan
          </a>
          <a
            class="link-manual ml-5"
            href="https://docs.cohuashop.com"
            target="_blank"
          >
            Manuales de uso
          </a>
          <a
            class="link ml-5"
            href="https://cohuashop.com/politica-de-privacidad"
            target="_blank"
          >
            Aviso de Privacidad
          </a>
          <a
            class="link ml-5"
            href="https://cohuashop.com/terminos-y-condiciones"
            target="_blank"
          >
            Términos de uso
          </a>
        </vs-col>
      </vs-row>
    </div>
  </footer>
</template>

<script>
import { eventBus } from '@/plugins/event-bus'

export default {
  name: 'NavBar',
  data: function () {
    return {
      activeItem: 0,
    }
  },
  beforeDestroy() {
    eventBus.$off('showSideBar', this.listener)
  },
  methods: {
    showSideBar: () => {
      eventBus.$emit('showSideBar')
    },
  },
}
</script>

<style lang="scss">
@import '~@/scss/_variables.scss';

footer#main-footer {
  background: $primary;
  left: 0;
  bottom: 0;
  width: 100%;
  color: #373a3c !important;
  text-align: center;

  .footer-header {
    font-size: 0.8rem;
    padding: 0.2rem 2rem;
    align-items: center;
    display: flex;
    min-height: $footer-height;
  }

  a.link {
    color: #fcfcfc;
    font-weight: 300;
    transition: all 0.3s ease;
    &:hover {
      color: #efefef;
    }
  }

  a.link-manual {
    color: greenyellow;
    font-weight: 300;
    transition: all 0.3s ease;
    &:hover {
      color: #efefef;
    }
  }
}
</style>
