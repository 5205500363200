var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('vs-sidebar',{staticClass:"sidebarx",attrs:{"default-index":_vm.getDefaultIndex(),"color":"primary","parent":"body","click-not-close":true,"spacer":""},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('div',{staticClass:"header-sidebar",attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"header-desc"},[_c('vs-button',{attrs:{"icon":"close","color":"primary","type":"flat"},on:{"click":function($event){_vm.active = false}}})],1),(_vm.user.userprofile.picture !== '#')?[_c('vs-avatar',{attrs:{"size":"70px","src":_vm.user.userprofile.picture}})]:_c('vs-avatar',{attrs:{"size":"70px","src":require('@/assets/images/user-avatar.png')}}),_c('h4',{staticClass:"small-font"},[_c('span',{staticClass:"long-text"},[_vm._v(" "+_vm._s(_vm.user.name)+" "+_vm._s(_vm.user.lastName)+" "+_vm._s(_vm.user.secondLastName))])]),_c('h4',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.user.userprofile.role)))]),_c('span',{staticClass:"small-font long-text"},[_vm._v(_vm._s(_vm.user.email))]),_c('h4',{staticClass:"mt-1"},[_c('span',{staticClass:"long-text"},[_vm._v(_vm._s(_vm.shop.name))])]),_c('small',{staticClass:"long-text small"},[_vm._v(" "+_vm._s(_vm.shop.packages.name.replace('_', ' '))+" ")])],2),_c('div',[_c('a',{staticClass:"side-bar-router side-bar-router-manual mb-1",attrs:{"href":"https://docs.cohuashop.com","target":"_blank"}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" help")]),_vm._v(" Manuales de uso ")])]),_c('vs-sidebar-group',{attrs:{"title":"Mi Perfil","open":false}},[_c('div',{on:{"click":function($event){return _vm.selectOption('Ver mi perfil')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",class:{ 'active-route': _vm.select === 'Ver mi perfil' },attrs:{"to":{
            name: 'MyProfile',
          }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" account_box ")]),_vm._v(" Ver mi perfil ")])],1)]),(!(_vm.user.userprofile.role === _vm.roles.ADMIN || _vm.user.userprofile.role === _vm.roles.MANAGER))?_c('vs-divider',{attrs:{"position":"left"}}):_vm._e(),(
        _vm.shop.packages.hasSalesModule &&
          (_vm.user.userprofile.role === _vm.roles.ADMIN ||
          _vm.user.userprofile.role === _vm.roles.MANAGER ||
          _vm.user.userprofile.role === _vm.roles.SELLER)
      )?_c('vs-sidebar-group',{attrs:{"title":"Ventas","open":false}},[_c('div',{on:{"click":function($event){return _vm.selectOption('nueva venta')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",class:{ 'active-route': _vm.select === 'nueva venta' },attrs:{"to":{
            name: 'newSale',
          }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" point_of_sale ")]),_vm._v(" Generar venta ")])],1),_c('div',{on:{"click":function($event){return _vm.selectOption('devolucion')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",class:{ 'active-route': _vm.select === 'devolucion' },attrs:{"to":{
            name: 'generateReturn',
          }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" assignment_return ")]),_vm._v(" Generar Devolución ")])],1),_c('div',{on:{"click":function($event){return _vm.selectOption('ventas')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",class:{ 'active-route': _vm.select === 'ventas' },attrs:{"to":{
            name: 'sales',
          }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" attach_money ")]),_vm._v(" Ventas ")])],1),(_vm.user.userprofile.role === _vm.roles.ADMIN || _vm.user.userprofile.role === _vm.roles.MANAGER)?_c('div',{on:{"click":function($event){return _vm.selectOption('salesAnalytics')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",class:{
            'active-route': _vm.select === 'salesAnalytics',
          },attrs:{"to":{
            name: 'salesAnalytics',
          }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" analytics ")]),_vm._v(" Analytics ")])],1):_vm._e()]):_vm._e(),(
        _vm.shop.packages.hasSalesModule &&
          (_vm.user.userprofile.role === _vm.roles.ADMIN || _vm.user.userprofile.role === _vm.roles.MANAGER)
      )?_c('vs-sidebar-group',{attrs:{"title":"Medios de Pago","open":false}},[(
          _vm.shop.packages.hasSalesModule &&
            (_vm.user.userprofile.role === _vm.roles.ADMIN || _vm.user.userprofile.role === _vm.roles.MANAGER)
        )?_c('div',{on:{"click":function($event){return _vm.selectOption('Cobros')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",class:{ 'active-route': _vm.select === 'Cobros' },attrs:{"to":{
            name: 'ShopCharges',
          }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" payment ")]),_vm._v(" Configuración ")])],1):_vm._e(),(
          _vm.shop.packages.hasSalesModule &&
            (_vm.user.userprofile.role === _vm.roles.ADMIN || _vm.user.userprofile.role === _vm.roles.MANAGER)
        )?_c('div',{on:{"click":function($event){return _vm.selectOption('Msi')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",class:{ 'active-route': _vm.select === 'Msi' },attrs:{"to":{
            name: 'MsiRules',
          }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" price_change ")]),_vm._v(" Reglas meses sin intereses ")])],1):_vm._e()]):_vm._e(),(
        _vm.user.userprofile.role === _vm.roles.ADMIN ||
          _vm.user.userprofile.role === _vm.roles.MANAGER ||
          _vm.user.userprofile.role === _vm.roles.SELLER
      )?_c('vs-sidebar-group',{attrs:{"title":"Inventario","open":false}},[_c('div',{on:{"click":function($event){return _vm.selectOption('Inventario')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",attrs:{"to":{
            name: 'generalInventory',
          }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" list")]),_vm._v(" Inventario General ")])],1),_c('div',{on:{"click":function($event){return _vm.selectOption('Movimientos - Inventario')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",attrs:{"to":{
            name: 'stockMovements',
          }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" list")]),_vm._v(" Movimientos de Stock ")])],1)]):_vm._e(),(
        _vm.user.userprofile.role === _vm.roles.ADMIN ||
          _vm.user.userprofile.role === _vm.roles.MANAGER ||
          _vm.user.userprofile.role === _vm.roles.SELLER
      )?_c('vs-sidebar-group',{attrs:{"title":"Productos","open":false}},[_c('div',{on:{"click":function($event){return _vm.selectOption('productos')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",class:{ 'active-route': _vm.select === 'productos' },attrs:{"to":{
            name: 'productList',
          }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" list ")]),_vm._v(" Lista de productos / servicios ")])],1),_c('div',{on:{"click":function($event){return _vm.selectOption('producto nuevo')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",class:{ 'active-route': _vm.select === 'producto nuevo' },attrs:{"to":{
            name: 'newProduct',
          }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" person_add ")]),_vm._v(" Agregar producto / servicio ")])],1),_c('div',{on:{"click":function($event){return _vm.selectOption('Configuración de productos')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",class:{ 'active-route': _vm.select === 'Configuración de productos' },attrs:{"to":{
            name: 'shopProductsSettings',
          }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" category ")]),_vm._v(" Configuración de productos ")])],1)]):_vm._e(),(
        _vm.shop.packages.hasSalesModule &&
          (_vm.user.userprofile.role === _vm.roles.ADMIN || _vm.user.userprofile.role === _vm.roles.MANAGER)
      )?_c('vs-sidebar-group',{attrs:{"title":"Cupones","open":false}},[_c('div',{on:{"click":function($event){return _vm.selectOption('cupones')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",class:{ 'active-route': _vm.select === 'cupones' },attrs:{"to":{
            name: 'Cupones',
          }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" takeout_dining ")]),_vm._v(" Cupones de Descuento ")])],1),_c('div',{on:{"click":function($event){return _vm.selectOption('crear-cupon')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",class:{ 'active-route': _vm.select === 'crear-cupon' },attrs:{"to":{
            name: 'newCupon',
          }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" local_shipping ")]),_vm._v(" Crear Cupón de Descuento ")])],1)]):_vm._e(),(
        _vm.shop.packages.hasSalesModule &&
          (_vm.user.userprofile.role === _vm.roles.ADMIN || _vm.user.userprofile.role === _vm.roles.MANAGER)
      )?_c('vs-sidebar-group',{attrs:{"title":"Envíos","open":false}},[_c('div',{on:{"click":function($event){return _vm.selectOption('paquetes')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",class:{ 'active-route': _vm.select === 'paquetes' },attrs:{"to":{
            name: 'Packaging',
          }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" takeout_dining ")]),_vm._v(" Paquetes ")])],1),_c('div',{on:{"click":function($event){return _vm.selectOption('envios')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",class:{ 'active-route': _vm.select === 'envios' },attrs:{"to":{
            name: 'shippingRules',
          }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" local_shipping ")]),_vm._v(" Reglas de envíos ")])],1)]):_vm._e(),(_vm.user.userprofile.role === _vm.roles.ADMIN || _vm.user.userprofile.role === _vm.roles.MANAGER)?_c('vs-sidebar-group',{attrs:{"title":"Mi Tienda","open":false}},[_c('div',{on:{"click":function($event){return _vm.selectOption('BarraContacto')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",class:{ 'active-route': _vm.select === 'BarraContacto' },attrs:{"to":{
            name: 'ShopContactBar',
          }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" contact_mail ")]),_vm._v(" Barra de contacto ")])],1),_c('div',{on:{"click":function($event){return _vm.selectOption('catalogo')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",class:{ 'active-route': _vm.select === 'catalogo' },attrs:{"to":{
            name: 'catalogs',
          }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" library_books ")]),_vm._v(" Catálogos ")])],1),_c('div',{on:{"click":function($event){return _vm.selectOption('Categorias')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",class:{ 'active-route': _vm.select === 'Categorias' },attrs:{"to":{
            name: 'listCategories',
          }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" category ")]),_vm._v(" Categorías ")])],1),(
          _vm.shop.packages.hasSalesModule &&
            (_vm.user.userprofile.role === _vm.roles.ADMIN || _vm.user.userprofile.role === _vm.roles.MANAGER)
        )?_c('div',{on:{"click":function($event){return _vm.selectOption('Facturacion')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",class:{ 'active-route': _vm.select === 'Facturacion' },attrs:{"to":{
            name: 'ShopBillings',
          }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" receipt_long ")]),_vm._v(" Facturación ")])],1):_vm._e(),_c('div',{on:{"click":function($event){return _vm.selectOption('Logos')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",class:{ 'active-route': _vm.select === 'Logos' },attrs:{"to":{
            name: 'shopImages',
          }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" collections ")]),_vm._v(" Logos / Banners ")])],1),_c('div',{on:{"click":function($event){return _vm.selectOption('Galeria')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",class:{ 'active-route': _vm.select === 'Galeria' },attrs:{"to":{
            name: 'shopGallery',
          }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" amp_stories ")]),_vm._v(" Galería ")])],1),_c('div',{on:{"click":function($event){return _vm.selectOption('Promociones')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",class:{ 'active-route': _vm.select === 'Promociones' },attrs:{"to":{
            name: 'ShopPromotions',
          }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" announcement ")]),_vm._v(" Promociones ")])],1),_c('div',{on:{"click":function($event){return _vm.selectOption('Proveedores')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",class:{ 'active-route': _vm.select === 'Proveedores' },attrs:{"to":{
            name: 'SupplierList',
          }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" groups ")]),_vm._v(" Proveedores / Clientes ")])],1),_c('div',{on:{"click":function($event){return _vm.selectOption('Redes Sociales')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",class:{ 'active-route': _vm.select === 'Redes Sociales' },attrs:{"to":{
            name: 'shopSocialNetworks',
          }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" public ")]),_vm._v(" Redes Sociales ")])],1),_c('div',{on:{"click":function($event){return _vm.selectOption('Secciones')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",class:{ 'active-route': _vm.select === 'Secciones' },attrs:{"to":{
            name: 'shopSections',
          }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" article ")]),_vm._v(" Secciones ")])],1),_c('div',{on:{"click":function($event){return _vm.selectOption('Sucursales')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",class:{ 'active-route': _vm.select === 'Sucursales' },attrs:{"to":{
            name: 'shopBranch',
          }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" store ")]),_vm._v(" Sucursales ")])],1),(_vm.user.userprofile.role === _vm.roles.ADMIN || _vm.user.userprofile.role === _vm.roles.MANAGER)?_c('div',{on:{"click":function($event){return _vm.selectOption('Usuarios')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",class:{ 'active-route': _vm.select === 'Usuarios' },attrs:{"to":{
            name: 'shopStaff',
          }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" supervised_user_circle ")]),_vm._v(" Usuarios ")])],1):_vm._e()]):_vm._e(),(_vm.user.userprofile.role === _vm.roles.ADMIN || _vm.user.userprofile.role === _vm.roles.MANAGER)?_c('vs-sidebar-group',{attrs:{"title":"Newsletter","open":false}},[_c('div',{on:{"click":function($event){return _vm.selectOption('Newsletter')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",class:{ 'active-route': _vm.select === 'Newsletter' },attrs:{"to":{
            name: 'NewsletterClient',
          }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" email ")]),_vm._v(" Suscripciones ")])],1),_c('div',{on:{"click":function($event){return _vm.selectOption('NewsletterConfig')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",class:{ 'active-route': _vm.select === 'NewsletterConfig' },attrs:{"to":{
            name: 'NewsletterConfiguration',
          }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" settings ")]),_vm._v(" Configuración de Newsletter ")])],1)]):_vm._e(),(_vm.user.userprofile.role === _vm.roles.ADMIN || _vm.user.userprofile.role === _vm.roles.MANAGER)?_c('vs-sidebar-group',{attrs:{"title":"Configuración","open":false}},[_c('div',{on:{"click":function($event){return _vm.selectOption('Configuracion')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",class:{ 'active-route': _vm.select === 'Configuracion' },attrs:{"to":{
            name: 'shopGeneralSettings',
          }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" settings ")]),_vm._v(" Configuración general ")])],1),(_vm.shop.packages.hasDomainSettingsModule)?_c('div',{on:{"click":function($event){return _vm.selectOption('dominio')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",class:{ 'active-route': _vm.select === 'dominio' },attrs:{"to":{
            name: 'shopDomain',
          }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" public ")]),_vm._v(" Configuración del dominio ")])],1):_vm._e(),(_vm.shop.packages.hasEmailSettingsModule)?_c('div',{on:{"click":function($event){return _vm.selectOption('emails')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",class:{ 'active-route': _vm.select === 'emails' },attrs:{"to":{
            name: 'shopEmails',
          }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" email ")]),_vm._v(" Configuración de emails ")])],1):_vm._e(),_c('div',{on:{"click":function($event){return _vm.selectOption('HTML embebido')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",class:{ 'active-route': _vm.select === 'HTML embebido' },attrs:{"to":{
            name: 'HTML-embebido',
          }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" takeout_dining ")]),_vm._v(" HTML embebido ")])],1)]):_vm._e(),(_vm.user.userprofile.role === _vm.roles.ADMIN)?_c('vs-divider',{attrs:{"position":"left"}}):_vm._e(),(_vm.user.userprofile.role === _vm.roles.ADMIN)?_c('vs-sidebar-group',{attrs:{"title":"Sistema","open":false}},[(_vm.user.userprofile.role === _vm.roles.ADMIN)?_c('div',{on:{"click":function($event){return _vm.selectOption('Administración de tiendas')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",class:{ 'active-route': _vm.select === 'Administración de tiendas' },attrs:{"to":{
            name: 'shopList',
          }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" view_list ")]),_vm._v(" Administración de tiendas ")])],1):_vm._e(),(_vm.user.userprofile.role === _vm.roles.ADMIN)?_c('div',{on:{"click":function($event){return _vm.selectOption('Administración de comisiones globales')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",class:{
            'active-route': _vm.select === 'Administración de comisiones globales',
          },attrs:{"to":{
            name: 'globalCommissions',
          }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" attach_money ")]),_vm._v(" Comisiones Globales ")])],1):_vm._e(),(_vm.user.userprofile.role === _vm.roles.ADMIN)?_c('div',{on:{"click":function($event){return _vm.selectOption('Aviso de Privacidad')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",class:{
            'active-route': _vm.select === 'Aviso de Privacidad',
          },attrs:{"to":{
            name: 'PrivacyNoticeView',
          }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" article ")]),_vm._v(" Aviso de Privacidad ")])],1):_vm._e(),(_vm.user.userprofile.role === _vm.roles.ADMIN)?_c('div',{on:{"click":function($event){return _vm.selectOption('Analytics')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",class:{
            'active-route': _vm.select === 'Analytics',
          },attrs:{"to":{
            name: 'Analytics',
          }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" analytics ")]),_vm._v(" Analytics ")])],1):_vm._e(),(_vm.user.userprofile.role === _vm.roles.ADMIN)?_c('div',{on:{"click":function($event){return _vm.selectOption('Catalogo admin')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",class:{ 'active-route': _vm.select === 'Catalogo admin' },attrs:{"to":{
            name: 'catalogsAdmin',
          }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" library_books ")]),_vm._v(" Catálogo administradores ")])],1):_vm._e(),(_vm.user.userprofile.role === _vm.roles.ADMIN)?_c('div',{on:{"click":function($event){return _vm.selectOption('Catalogo admin')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",class:{ 'active-route': _vm.select === 'Notificaciones' },attrs:{"to":{
            name: 'Notifications',
          }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" notifications ")]),_vm._v(" Notificaciones ")])],1):_vm._e()]):_vm._e(),_c('div',{staticClass:"footer-sidebar",attrs:{"slot":"footer"},slot:"footer"},[_c('vs-button',{attrs:{"icon":"reply","color":"danger","type":"flat"},on:{"click":_vm._logout}},[_vm._v(" Cerrar Sesión ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }